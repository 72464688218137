import { isTagReadable } from "@/components/ui/tags/tag-accessibility";
import { GUID } from "@faro-lotv/foundation";
import { IElement, isIElementProjectRoot } from "@faro-lotv/ielement-types";
import { selectIElement } from "@faro-lotv/project-source";
import { RootState } from "../store";
import { Tag, UNTAGGED } from "./tags-slice";

/**
 * @returns All the tags in the project
 */
export function selectTags({ tags }: RootState): Tag[] {
  return tags.tags;
}

/**
 * @returns The list of tags selected by the user
 */
export function selectSelectedTags({ tags }: RootState): Tag[] {
  return tags.selectedTags;
}

/**
 *
 * @param elements list of elements to filter
 * @param selectReferenceElement function used to find an element of a specific type.
 * If defined it means that the tags are contained in an ancestor or child of the passed elements
 * @returns a filtered version of the passed list of element. It's filtered based on if any of the tags of the element is also present in
 * the list of selected tags by the user
 */
export function selectFilteredElementsWithTags<
  T extends IElement,
  K extends IElement,
>(
  elements: T[],
  selectReferenceElement?: (element: T) => (store: RootState) => K | undefined,
): (state: RootState) => T[] {
  return (state: RootState): T[] => {
    // If the predicate is defined then get the tagged IElement, otherwise use directly the passed list
    const elementsToFilter = selectReferenceElement
      ? elements.flatMap((el) => selectReferenceElement(el)(state))
      : elements;

    // Get the selected tags from the store
    const selectedTagsIds = selectSelectedTags(state).map((tag) => tag.id);

    // Filtered list of the passed elements
    let filteredElements: T[] = [];

    // If the store does not have any selected tags, then return the passed list of elements
    if (selectedTagsIds.length === 0) {
      filteredElements = elements;
    } else {
      for (const [idx, el] of elementsToFilter.entries()) {
        if (matchIElementTag(el, selectedTagsIds)) {
          filteredElements.push(elements[idx]);
        }
      }
    }

    return filteredElements;
  };
}

/**
 * @returns The list of input elements whose at least one ancestor has one of the selectedTags
 * @param elements The list of elements to filter
 * @param maxDepth The max depth allowed when searching for an ancestor
 */
export function selectFilteredElementsByAncestor<T extends IElement>(
  elements: T[],
  maxDepth: number = Number.POSITIVE_INFINITY,
): (state: RootState) => T[] {
  return (state: RootState): T[] => {
    const filteredElements: T[] = [];
    // Get the selected tags from the store
    const selectedTagsIds = selectSelectedTags(state).map((tag) => tag.id);
    if (selectedTagsIds.length === 0) {
      return elements;
    }

    const lookForUntagged = selectedTagsIds.includes(UNTAGGED.id);

    // Check if at least one ancestor matches one of the selected tags
    for (const element of elements) {
      let depth = maxDepth;
      let ancestor: IElement | undefined = element;
      let isSubTreeUntagged = true;
      while (ancestor && !isIElementProjectRoot(ancestor)) {
        if (hasIElementTag(ancestor, selectedTagsIds)) {
          filteredElements.push(element);
          ancestor = undefined;
          continue;
        }
        isSubTreeUntagged = isSubTreeUntagged && isIElementUntagged(ancestor);
        --depth;
        ancestor =
          depth === 0 ? undefined : selectIElement(ancestor.parentId)(state);
      }
      // If we reached the end of the while loop and the element was not yet added to the list,
      // but all of the ancestors have no tags and the UNTAGGED label was selected by the user, add the
      // element to the filtered list
      if (
        filteredElements[filteredElements.length - 1] !== element &&
        lookForUntagged &&
        isSubTreeUntagged
      ) {
        filteredElements.push(element);
      }
    }

    return filteredElements;
  };
}

/**
 * @returns True if the input element labels match one of the provided tags
 * @param iElement the iElement to check
 * @param tags The list of tags used to check for matching
 */
function matchIElementTag(
  iElement: IElement | undefined,
  tags: GUID[],
): boolean {
  if (!iElement) {
    return false;
  }

  // If the current element does not have tags (it's untagged) and the selected tags in the store
  // have the UNTAGGED tag, then keep the element so that it's returned.
  // Otherwise, if any tag present in the list of tags of the current element is also present in the list of
  // selected tags in the store, then keep the element so that it's returned.
  if (
    (isIElementUntagged(iElement) && tags.includes(UNTAGGED.id)) ||
    hasIElementTag(iElement, tags)
  ) {
    return true;
  }

  return false;
}

/**
 * @returns True if the iElement contains at least one of the tags in the input list
 * @param iElement The iElement to check
 * @param tags The list of tags used to check agains
 */
function hasIElementTag(iElement: IElement, tags: GUID[]): boolean {
  // Get the ids of the tags of the current element in the list
  const labelsIds = iElement.labels
    ?.filter(isTagReadable)
    .map((label) => label.id);
  return !!labelsIds?.some((labelId) => tags.includes(labelId));
}

/**
 * @returns True if the iElement has no readable labels
 * @param iElement The iEleement to check
 */
function isIElementUntagged(iElement: IElement): boolean {
  return !iElement.labels || iElement.labels.filter(isTagReadable).length === 0;
}
